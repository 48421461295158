import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { IDateValidatorDetails } from 'src/app/schemes/types/form-validation';
import { TimeService } from 'src/app/services/time.service';
import { CUSTOM_FORM_ERRORS_ID } from '../../schemes/types/form-validation';

@Injectable({
	providedIn: 'root',
})
export class CustomFormDatesValidator {
	/**
	 * Sets a form control error if the date field is later than the reference date field.
	 * @param dateField Main date field.
	 * @param comparatorDateField Reference date field to compare with.
	 * @param showErrorOnInput If true, the error will be shown on the input field.
	 * @param customMessage If provided, the error message will be replaced by this message.
	 * @returns
	 */
	dateIsLaterThan(
		dateField: string,
		comparatorDateField: string,
		showErrorOnInput: boolean = false,
		customMessage?: string
	): ValidatorFn {
		return (formGroup: FormGroup): ValidationErrors => {
			const dateFieldController = formGroup.get(dateField) as FormControl;
			const comparatorDateFieldController = formGroup.get(comparatorDateField) as FormControl;
			if (!dateFieldController.value || !comparatorDateFieldController.value) return null;

			const dateIsLaterOrEqual =
				TimeService.parseDate(dateFieldController.value) >=
				TimeService.parseDate(comparatorDateFieldController.value);

			if (!dateIsLaterOrEqual) {
				const error: { invalidDate: IDateValidatorDetails } = {
					invalidDate: { customMessage: customMessage ?? null },
				};
				if (showErrorOnInput) {
					dateFieldController.setErrors(error);
				}
				return error;
			}

			if (dateFieldController.hasError(CUSTOM_FORM_ERRORS_ID.CUSTOM_ERROR_INVALID_DATE_ID)) {
				delete dateFieldController.errors[CUSTOM_FORM_ERRORS_ID.CUSTOM_ERROR_INVALID_DATE_ID];
				dateFieldController.updateValueAndValidity({ onlySelf: true });
			}
			return null;
		};
	}

	preventFutureDates(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const isFutureDate = TimeService.parseDate(new Date()) < new Date(control.value);
			return isFutureDate ? { futureDate: true } : null;
		};
	}
}
