import { Injectable } from '@angular/core';
import { MorfologicQualificationModel } from '../schemes/models/qualifications.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';

@Injectable({
	providedIn: 'root',
})
export class MorfologicQualificationProvider extends BaseProvider<MorfologicQualificationModel> {
	constructor(http: HttpService) {
		super('morfologic-qualifications', http);
	}
}
