export const GENERATING_FILE_MESSAGE = {
	data: {
		title: 'Se está generando el fichero',
		msg: 'Este proceso puede durar unos minutos, te notificaremos cuando se complete',
	},
};

export const EMPTY_FILE_MESSAGE = {
	data: {
		title: 'No es posible generar el fichero',
		msg: 'Ningún animal de la raza seleccionada cumple con los requisitos para generar el informe',
	},
};
