import { Injectable } from '@angular/core';
import { ResurrectionModel } from '../schemes/models/resurrection.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';

@Injectable({
	providedIn: 'root',
})
export class ResurrectionProvider extends BaseProvider<ResurrectionModel> {
	constructor(http: HttpService) {
		super('resurrections', http);
	}
}
