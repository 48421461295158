const reloadPageMessage = 'Se recargará la página para mostrar los cambios';
const contactUs =
	'Contacta con el equipo de GESCAN para ajustar las reglas de libro o asignar una categoría de forma manual';

export const GenBookModalDataTexts = {
	success: {
		title: 'Categoría calculada con éxito',
		msg: reloadPageMessage,
	},

	successfully_blocked: {
		title: 'Categoría bloqueada con éxito',
		msg: reloadPageMessage,
	},

	calibration_failed: {
		title: 'Ha ocurrido un error al realizar el cálculo',
		msg: contactUs,
	},

	category_not_found: {
		title: 'No se ha podido encontrar una nueva categoría',
		msg: contactUs,
	},
};

export const GenBookModalMessageTexts = {
	apply_or_block_question:
		'¿Desea aplicar esta categoría al animal? ¿O prefiere bloquear la categoría actual?',
};

export const GenBookModalsButtonTexts = {
	accept: 'Aplicar',
	decline: 'Bloquear',
};

export const UploadImageModalErrorTexts = {
	title: 'No es posible procesar el archivo',
	msg: 'Es posible que la imagen sea demasiado grande o tenga una extensión incorrecta.',
	mode: 'error',
};

export const UploadImageHistoryModalErrorTexts = {
	title: 'No es posible procesar el archivo.',
	msg: 'Ha alcanzado el número máximo de imágenes en el historial [10].',
	mode: 'error',
};

export const LoadImageSizeWarningTexts = {
	title: 'Advertencia',
	msg: 'Espere un momento para poder actualizar la imagen',
	mode: 'warning',
};
