import { Injectable } from '@angular/core';
import { TransferenceModel } from '../schemes/models/transferences.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';

@Injectable({
	providedIn: 'root',
})
export class TransferencesProvider extends BaseProvider<TransferenceModel> {
	constructor(http: HttpService) {
		super('transferences', http);
	}
}
