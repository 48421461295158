import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { FarmModel } from '../schemes/models/farm.model';
import { FarmService } from '../services/farm.service';
import { PageNavigationService } from '../services/page-navigation.service';

@Injectable({ providedIn: 'root' })
export class CurrentFarmResolver implements Resolve<FarmModel | null> {
	constructor(private farmService: FarmService, private pageNavigationService: PageNavigationService) {}

	async resolve(): Promise<FarmModel | null> {
		try {
			const farm = await this.farmService.getCurrentFarm();
			return farm;
		} catch (error) {
			this.pageNavigationService.onBack();
			return null;
		}
	}
}
